document.addEventListener('DOMContentLoaded', function () {
    const burger = document.querySelector(".navbar-toggler");
    const filter = document.querySelector(".black-filter");
    const navCollapse = document.querySelector(".navbar-collapse");
    var navCollapseBs = new bootstrap.Collapse(navCollapse, {
        toggle: false
    })
    const sidebarCart = document.querySelector('.sidebarcart');
    const closeCart = document.getElementById('closeCart');
    const sidebarCartToggler = document.querySelector('.sidebar-cart-toggler');
    const body = document.querySelector("body");
    const nav = document.querySelector("nav.main-nav");
    const filterBody = () => {
        body.classList.add('filter');
        body.classList.add('no-scroll');
        nav.classList.add('transparent');
        return;
    }
    const unfilterBody = () => {
        body.classList.remove('filter');
        body.classList.remove('no-scroll');
        nav.classList.remove('transparent');

        return;
    }


    if(sidebarCart){
        sidebarCartToggler.addEventListener("click", (e)=> {
            e.preventDefault();
            sidebarCart.classList.add("show");
            filterBody();
        })
        closeCart.addEventListener("click", (e)=> {
            e.preventDefault();
            sidebarCart.classList.remove("show");
            unfilterBody();
        });
       filter.addEventListener("click", (e)=> {
            e.preventDefault();
            sidebarCart.classList.remove("show");
            unfilterBody();
            navCollapseBs.hide();
        });
    }

    if(navCollapse){
        navCollapse.addEventListener("show.bs.collapse", (e) => {
            filterBody();
            return
        });
        navCollapse.addEventListener("hide.bs.collapse", (e) => {
            unfilterBody()
            return
        });
        window.addEventListener("resize", ()=> {
            if (window.innerWidth > 992 && body.classList.contains("no-scroll")) {
                unfilterBody();
                sidebarCart.classList.remove("show");
                return
            }
            if (window.innerWidth < 992 && navCollapse.classList.contains("show") && !body.classList.contains("no-scroll")){
                filterBody();
                return;
            }
        })
    }




});