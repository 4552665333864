document.addEventListener("DOMContentLoaded", function() {
    if (document.querySelector('.landing-swiper')){
        const landingSwiper = new Swiper(`.landing-swiper`, {
            speed: 400,
            spaceBetween: 100,
            pagination: {
                el: '.landing-swiper .swiper-pagination',
                type: 'bullets',
            },
        });
    }
});