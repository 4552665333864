const cardSelect = document.querySelectorAll(".card-select-wrap");
if (cardSelect.length) {
    Array.from(cardSelect).forEach(select => {
        const selectMenu = select.querySelector(".card-select-options");
        const selectedCardInput = select.querySelector(".card-select-readonly");
        const inputWrap = select.querySelector(".input-wrap");
        const cardOptions = Array.from(select.querySelectorAll("input[type='radio']"));
        select.addEventListener("click", (e)=> {
            if (inputWrap.contains(e.target)) {
                selectMenu.classList.toggle("show");
                inputWrap.classList.toggle("collapsed");
                return;
            }
        });
        cardOptions.forEach(option => {
            option.addEventListener("click", (e) => {
                if (option.checked) {
                    const selectedImage = e.target.nextElementSibling.querySelector('img');
                    const selectedCardTitle = e.target.nextElementSibling.querySelector('h6');
                    const selectedCardDetail = e.target.nextElementSibling.querySelector('span');
                    inputWrap.querySelector("img").src = selectedImage.src;
                    inputWrap.querySelector("h6").innerHTML = selectedCardTitle.innerHTML;
                    inputWrap.querySelector("span").innerHTML = selectedCardDetail.innerHTML;
                    selectedCardInput.value= option.value;
                }
                selectMenu.classList.remove("show");
                inputWrap.classList.add("collapsed");
                return;
            });
        });
        document.addEventListener('click', event => {
            const isClickInside = select.contains(event.target)
            if (!isClickInside) {
                selectMenu.classList.remove("show");
                inputWrap.classList.add("collapsed");
                return
            }
        });
    })
}