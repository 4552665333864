document.addEventListener('DOMContentLoaded', function () {
    const productInnerSwipers = document.querySelectorAll(".product-inner-swiper");
    let productInnerSwipersArr = [];
    if (productInnerSwipers.length) {
        Array.from(productInnerSwipers).forEach((swiper, index) => {
            const swiperID = swiper.id;
            productInnerSwipersArr[index] = new Swiper(`#${swiperID}`, {
                    spaceBetween: 15,
                    speed: 300,
                    slidesPerView: 4,
                    watchOverflow: true,

                });

            const imagePlaceholder = document.querySelector(`.img-placeholder img`);
            const slides = swiper.querySelectorAll(".swiper-slide");
            Array.from(slides).forEach(slide => {
                slide.addEventListener("click", (e) => {
                    imagePlaceholder.classList.add("transitioning");
                    setTimeout(()=> {
                        imagePlaceholder.classList.remove("transitioning");
                        imagePlaceholder.src = e.target.src;

                    }, 150)
                    Array.from(slides).forEach(slide => {
                        slide.classList.remove("active-slide")
                    })
                    slide.classList.add("active-slide");
                })
            });
        })
    }
});
